// screens/CreatePostScreen.js
import React, { useEffect, useState, useContext } from 'react';
import {
  View,
  Text,
  StyleSheet,
  TextInput,
  Image,
  TouchableOpacity,
  ScrollView,
  FlatList,
  Picker,
  ActivityIndicator,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import * as ImagePicker from 'expo-image-picker';
import { createPost, fetchHubs, uploadImageAsync } from '../firebase_services/postService';
import ModelPost from '../models/ModelPost';
import CategoryDropdown from '../components/CategoryDropdown';
import { UserContext } from '../UserContext';
import { Dialog, Portal, Button, Paragraph } from 'react-native-paper'; // Import Dialog components

const CreatePostScreen = () => {
  const navigation = useNavigation();
  const { user } = useContext(UserContext); // Access user data from context

  const [postContent, setPostContent] = useState('');
  const [postAttachments, setPostAttachments] = useState([]);
  const [hubs, setHubs] = useState([]);
  const [selectedHub, setSelectedHub] = useState(null); // Store the selected hub object
  const [tags, setTags] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [loadingHubs, setLoadingHubs] = useState(false); // New state for loading hubs

  // Dialog state variables
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  // Initialize stream, degree, and branch from user profile
  const [stream, setStream] = useState(user?.userStream || '');
  const [degree, setDegree] = useState(user?.userDegree || '');
  const [branch, setBranch] = useState(user?.userDegreeBranch || '');

  useEffect(() => {
    getHubs();
  }, []);

  // Fetch hubs from Firestore
  const getHubs = async () => {
    setLoadingHubs(true); // Start loading
    try {
      const hubsList = await fetchHubs();
      console.log('Fetched hubs:', hubsList); // Should log an array of hub objects
      setHubs(hubsList);
    } catch (error) {
      console.error('Error fetching hubs:', error);
      showDialog('Error', 'Failed to fetch hubs.');
    }
    setLoadingHubs(false); // End loading
  };

  // Update stream, degree, branch if user data changes
  useEffect(() => {
    setStream(user?.userStream || '');
    setDegree(user?.userDegree || '');
    setBranch(user?.userDegreeBranch || '');
  }, [user]);

  // Function to show dialog
  const showDialog = (title, message) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogVisible(true);
  };

  // Function to hide dialog
  const hideDialog = () => {
    setDialogVisible(false);
  };

  // Pick Image from Gallery
  const pickImage = async () => {
    try {
      const permissionResult = await ImagePicker.requestMediaLibraryPermissionsAsync();
      if (permissionResult.status !== 'granted') {
        showDialog('Permission Denied', 'Permission to access camera roll is required!');
        return;
      }

      let result = await ImagePicker.launchImageLibraryAsync({
        mediaTypes: ImagePicker.MediaTypeOptions.Images,
        allowsMultipleSelection: false, // Expo ImagePicker may not support multiple on all platforms
        quality: 0.7,
      });

      if (!result.cancelled) {
        // Updated to use result.assets[0].uri
        setPostAttachments([...postAttachments, result.assets[0].uri]);
      }
    } catch (error) {
      console.error('Error picking image:', error);
      showDialog('Error', 'Something went wrong while picking the image.');
    }
  };

  // Remove selected image
  const removeImage = (uri) => {
    setPostAttachments(postAttachments.filter((image) => image !== uri));
  };

  // Handle Tag Input Change
  const handleTagsChange = (text) => {
    setTags(text);
  };

  // Handle Post Submission
  const handleSubmit = async () => {
    console.log('handleSubmit invoked'); // Debugging statement
    console.log('postContent:', postContent); // Debugging statement
    console.log('stream:', stream); // Debugging statement
    console.log('user:', user); // Debugging statement

    // Validations
    if (postContent.trim() === '') {
      showDialog('Validation Error', 'Post content cannot be empty.');
      return;
    }

    if (stream.trim() === '') {
      showDialog('Validation Error', 'Stream is required.');
      return;
    }

    if (!user) {
      showDialog('Authentication Required', 'Please log in to create a post.');
      return;
    }

    setIsSubmitting(true);
    try {
      // Upload images and get URLs
      const uploadedImageUrls = [];
      for (const uri of postAttachments) {
        const url = await uploadImageAsync(uri, user.userId);
        uploadedImageUrls.push(url);
      }
  
      // Prepare tags array
      const tagsArray = tags
        .split(',')
        .map((tag) => tag.trim())
        .filter((tag) => tag.length > 0);
  
      // Prepare post data
      const postData = new ModelPost(
        '', // postId will be set by Firestore
        postContent,
        uploadedImageUrls,
        user.userId,
        user.userName || 'Anonymous',
        user.userProfilePicture || 'https://example.com/default-user.png',
        user.userRole || 'User', // Default role
        new Date(),
        [], // postLikes
        [], // postReports
        tagsArray,
        selectedHub ? selectedHub.hubId : null, // Use hubId if selected
        null, // parentPostId set to null for top-level posts
        stream,
        degree,
        branch
      );
  
      // Create post
      const postId = await createPost(postData);
      showDialog('Success', 'Post created successfully!');
      navigation.goBack();
    } catch (error) {
      console.error('Error creating post:', error);
      showDialog('Error', 'There was an error creating your post.');
    }
    setIsSubmitting(false);
  };

  return (
    <ScrollView contentContainerStyle={styles.container}>
      {/* Header */}
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#fff" />
        </TouchableOpacity>
        <Text style={styles.headerTitle}>Create Post</Text>
        <TouchableOpacity onPress={handleSubmit} style={styles.doneButton} disabled={isSubmitting}>
          {isSubmitting ? (
            <ActivityIndicator size="small" color="#fff" />
          ) : (
            <Ionicons name="checkmark" size={24} color="#fff" />
          )}
        </TouchableOpacity>
      </View>

      {/* Category Dropdown for Stream, Degree, Branch */}
      <CategoryDropdown
        selectedStream={stream}
        onStreamChange={setStream}
        selectedDegree={degree}
        onDegreeChange={setDegree}
        selectedBranch={branch}
        onBranchChange={setBranch}
      />

      {/* Post Content Input */}
      <Text style={styles.label}>Content</Text>
      <TextInput
        style={styles.input}
        placeholder="What's on your mind?"
        value={postContent}
        onChangeText={setPostContent}
        multiline
      />

      {/* Attachments */}
      <Text style={styles.label}>Attachments</Text>
      <View style={styles.attachmentsContainer}>
        <FlatList
          data={postAttachments}
          horizontal
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <View style={styles.attachmentWrapper}>
              <Image source={{ uri: item }} style={styles.attachmentImage} />
              <TouchableOpacity onPress={() => removeImage(item)} style={styles.removeImageButton}>
                <Ionicons name="close-circle" size={20} color="red" />
              </TouchableOpacity>
            </View>
          )}
          showsHorizontalScrollIndicator={false}
        />
        <TouchableOpacity style={styles.addImageButton} onPress={pickImage}>
          <Ionicons name="add" size={30} color="#007BFF" />
        </TouchableOpacity>
      </View>

      {/* Tags Input */}
      <Text style={styles.label}>Tags</Text>
      <TextInput
        style={styles.input}
        placeholder="Enter tags separated by commas (e.g., #react, #firebase)"
        value={tags}
        onChangeText={handleTagsChange}
      />

      {/* Hub Selection Picker */}
      <Text style={styles.label}>Select Hub</Text>
      {loadingHubs ? (
        <ActivityIndicator size="small" color="#007BFF" style={styles.hubLoader} />
      ) : (
        <View style={styles.pickerContainer}>
          <Picker
            selectedValue={selectedHub ? selectedHub.hubId : ''}
            onValueChange={(itemValue) => {
              const hub = hubs.find((h) => h.hubId === itemValue) || null;
              setSelectedHub(hub);
            }}
            style={styles.picker}
            prompt="Select a Hub"
          >
            <Picker.Item label="Select a Hub" value="" />
            {hubs.length > 0 ? (
              hubs.map((hub) => (
                <Picker.Item key={hub.hubId} label={hub.hubTitle} value={hub.hubId} />
              ))
            ) : (
              <Picker.Item label="No Hubs Available" value="" />
            )}
          </Picker>
        </View>
      )}

      {/* Dialogs */}
      <Portal>
        <Dialog visible={dialogVisible} onDismiss={hideDialog}>
          <Dialog.Title>{dialogTitle}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{dialogMessage}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <Button onPress={hideDialog}>OK</Button>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </ScrollView>
  );
};

const styles = StyleSheet.create({
  container: {
    padding: 15,
    backgroundColor: '#F1EEE6',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: 15,
    backgroundColor: '#007BFF', // Changed background color to primary
    paddingVertical: 15,
    paddingHorizontal: 10,
    borderRadius: 10,
    elevation: 5, // For Android shadow
    shadowColor: '#000', // For iOS shadow
    shadowOpacity: 0.2,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
  },
  backButton: {
    padding: 10,
    backgroundColor: '#0056b3', // Darker shade for back button
    borderRadius: 20,
  },
  headerTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#fff',
    flex: 1,
    textAlign: 'center',
    marginHorizontal: 10,
  },
  doneButton: {
    padding: 10,
    backgroundColor: '#28a745', // Green color to highlight the done button
    borderRadius: 20,
  },
  label: {
    fontSize: 16,
    marginBottom: 5,
    color: '#333',
    fontWeight: '600',
  },
  input: {
    height: 100,
    borderColor: '#e0e0e0',
    borderWidth: 1,
    borderRadius: 10,
    paddingHorizontal: 15,
    paddingVertical: 10, // Added padding inside the content box
    textAlignVertical: 'top',
    backgroundColor: '#ffffff',
    marginBottom: 15,
    fontSize: 16,
    color: '#333',
  },
  pickerContainer: {
    backgroundColor: '#ffffff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#e0e0e0',
    marginBottom: 15,
  },
  picker: {
    height: 50,
    width: '100%',
    color: '#333', // Text color inside picker
  },
  attachmentsContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 15,
  },
  attachmentWrapper: {
    position: 'relative',
    marginRight: 10,
  },
  attachmentImage: {
    width: 100,
    height: 100,
    borderRadius: 10,
  },
  removeImageButton: {
    position: 'absolute',
    top: -5,
    right: -5,
    backgroundColor: '#ffffff',
    borderRadius: 10,
  },
  addImageButton: {
    width: 100,
    height: 100,
    borderRadius: 10,
    borderWidth: 2,
    borderColor: '#007BFF',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#e9f0ff',
  },
  hubLoader: {
    marginVertical: 10,
  },
});

export default CreatePostScreen;
