// screens/PostDetailScreen.js
import React, { useEffect, useState, useContext } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  TextInput,
  Button,
  Alert,
  KeyboardAvoidingView,
  Platform,
  ScrollView
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { addReply, toggleLike, subscribeToReplies } from '../firebase_services/postService';
import ModelPost from '../models/ModelPost';
import { auth } from '../firebaseConfig';
import { UserContext } from '../UserContext'; // Import UserContext
import { Dialog, Portal, Button as PaperButton, Paragraph } from 'react-native-paper'; // For dialogs
import PostCardComponent from '../components/PostCardComponent';
import { Timestamp } from 'firebase/firestore';

const PostDetailScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { post } = route.params;

  const { user } = useContext(UserContext); // Access user data

  const [replies, setReplies] = useState([]);
  const [loadingReplies, setLoadingReplies] = useState(true);
  const [newReply, setNewReply] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLiked, setIsLiked] = useState(false);
  const [replyLikesCount, setReplyLikesCount] = useState(0);
  // Dialog state variables
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  useEffect(() => {
    if (post) {
      setIsLiked(post.postLikes.includes(user?.userId));
      // Subscribe to real-time replies
      const unsubscribe = subscribeToReplies(post.postId, (fetchedReplies) => {
        setReplies(fetchedReplies);
        setLoadingReplies(false);
      });

      return () => unsubscribe(); // Cleanup on unmount
    }
  }, [post, user]);

  // Function to show dialog
  const showDialog = (title, message) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogVisible(true);
  };

  // Function to hide dialog
  const hideDialog = () => {
    setDialogVisible(false);
  };

  const handleAddReply = async () => {
    if (newReply.trim() === '') {
      showDialog('Validation Error', 'Reply cannot be empty.');
      return;
    }

    if (!user) {
      showDialog('Authentication Required', 'Please log in to reply.');
      return;
    }

    setIsSubmitting(true);
    try {
      const replyPost = new ModelPost(
        '', // Firestore will assign an ID
        newReply,
        [], // No attachments for replies
        user.userId,
        user.userName || 'Anonymous',
        user.userProfilePicture || 'https://example.com/default-user.png',
        user.userRole || 'User', // Adjust role as needed
        new Date(),
        [], // Initialize with empty likes
        [], // Initialize with empty reports
        [], // Initialize with empty tags
        post.postHub || null, // Inherit hub from parent post
        post.postId, // Set parentPostId to current post's ID
        user.stream || '',   // Existing Field
        user.degree || '',   // Existing Field
        user.branch || ''    // Existing Field
      );

      await addReply(post.postId, replyPost);
      setNewReply('');
      // Replies will update in real-time via subscription
    } catch (error) {
      console.error('Error adding reply:', error);
      showDialog('Error', 'There was an error adding your reply.');
    }
    setIsSubmitting(false);
  };

  // const handleReplyShare = (item) => {
  //   showDialog('Feature Unavailability', 'This feature is not available right now.');
  // }
  
  // const handleReplyReply = (item) => {
  //   showDialog('Feature Unavailability', 'This feature is not available right now.');
  // }
  // const handlePostClick = (post) => {

  // }
  // handleReplyClick = (post) => {

  // }

  const handleReplyPostClick = (item) => {
    const serializablePost = {
      ...item,
      postCreatedAt: item.postCreatedAt.toISOString(),
      postUpdatedAt: item.postUpdatedAt ? item.postUpdatedAt.toISOString() : null,
    };
    
    navigation.navigate('PostDetail', { post: serializablePost });
  }

  // Handle Like Button Click
  // const handleReplyLike = async (postId) => {
  //   if (!user) {
  //     showDialog('Authentication Required', 'Please log in to like posts.');
  //     return;
  //   }
  //   try {
  //     setReplyLikesCount((await toggleLike(postId, auth.currentUser.uid)).likesCount);
  //   } catch (error) {
  //     console.error('Error liking post:', error);
  //     Alert.alert('Error', 'There was an error liking the post.');
  //   }
  // };

  const renderReply = ({ item }) => (
    <TouchableOpacity onPress={() => handleReplyPostClick(item)}>
      <View style={styles.replyItem}>
        {item.postAuthorPicture ? (
          <Image source={{ uri: item.postAuthorPicture }} style={styles.replyImage} />
        ) : (
          <Ionicons name="person-circle-outline" size={40} color="#ccc" />
        )}
        <View style={styles.replyContent}>
          <Text style={styles.replyAuthor}>{item.postAuthorName}</Text>
          <Text style={styles.replyText}>{item.postContent}</Text>
        </View>
      </View>
    </TouchableOpacity>
  );

  if (!post) {
    return (
      <View style={styles.centered}>
        <Text style={styles.errorText}>No post data available.</Text>
      </View>
    );
  }

  return (
    <KeyboardAvoidingView
      style={styles.container}
      behavior={Platform.OS === "ios" ? "padding" : undefined}
      keyboardVerticalOffset={Platform.OS === "ios" ? 100 : 0}
    >
      <ScrollView contentContainerStyle={styles.scrollContainer}>
        {/* Header */}
        <View style={styles.header}>
          <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
            <Ionicons name="arrow-back" size={24} color="#007BFF" />
            <Text style={styles.backButtonText}>Back</Text>
          </TouchableOpacity>
          <Text style={styles.headerTitle}>Post Detail</Text>
          <View style={{ width: 60 }} /> {/* Placeholder for alignment */}
        </View>

        {/* Post Content */}
        <View>

          <PostCardComponent
            post={post}
            onReply={() => handleReply(item)}
            onPostClick={() => handlePostClick(item)}
          />

          <View style={styles.postActions}>
            {/* Like Button */}
            {/* <TouchableOpacity onPress={handleLike} style={styles.actionButton}>
              <Ionicons name={isLiked ? "heart" : "heart-outline"} size={24} color={isLiked ? "red" : "#333"} />
              <Text style={[styles.actionText, isLiked && styles.likedText]}>{post.postLikes.length}</Text>
            </TouchableOpacity> */}
            {/* Share Button */}
            {/* <TouchableOpacity onPress={() => { }} style={styles.actionButton}>
              <Ionicons name="share-social-outline" size={24} color="#333" />
              <Text style={styles.actionText}>Share</Text>
            </TouchableOpacity> */}
            {/* Reply Button */}
            {/* <TouchableOpacity onPress={handleAddReply} style={styles.actionButton}>
              <Ionicons name="chatbubble-outline" size={24} color="#333" />
              <Text style={styles.actionText}>Reply</Text>
            </TouchableOpacity> */}
          </View>
        </View>

        {/* Replies Section */}
        <View style={styles.repliesContainer}>
          <Text style={styles.repliesTitle}>Replies</Text>
          {loadingReplies ? (
            <ActivityIndicator size="large" color="#007BFF" />
          ) : (
            <FlatList
              data={replies}
              keyExtractor={(item) => item.postId}
              renderItem={renderReply}
              contentContainerStyle={styles.repliesList}
              ListEmptyComponent={<Text style={styles.noRepliesText}>No replies yet.</Text>}
            />
          )}
        </View>
      </ScrollView>

      {/* Add Reply */}
      <View style={styles.addReplyContainer}>
        <TextInput
          style={styles.replyInput}
          placeholder="Write a reply..."
          value={newReply}
          onChangeText={setNewReply}
          multiline
        />
        <TouchableOpacity onPress={handleAddReply} style={styles.replyButton} disabled={isSubmitting}>
          <Ionicons name="send" size={24} color="#fff" />
        </TouchableOpacity>
      </View>

      {/* Dialogs */}
      <Portal>
        <Dialog visible={dialogVisible} onDismiss={hideDialog}>
          <Dialog.Title>{dialogTitle}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{dialogMessage}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <PaperButton onPress={hideDialog}>OK</PaperButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </KeyboardAvoidingView>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6',
  },
  scrollContainer: {
    paddingBottom: 100, // To ensure content is not hidden behind the reply input
  },
  centered: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  errorText: {
    fontSize: 18,
    color: '#666',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    paddingHorizontal: 15,
    backgroundColor: '#ffffff',
    borderBottomWidth: 1,
    borderBottomColor: '#e0e0e0',
  },
  backButton: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 5,
  },
  backButtonText: {
    color: '#007BFF',
    fontSize: 16,
    marginLeft: 5,
  },
  headerTitle: {
    flex: 1,
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 'bold',
    color: '#007BFF',
  },
  postContainer: {
    backgroundColor: '#ffffff',
    margin: 15,
    borderRadius: 10,
    padding: 15,
    elevation: 2,
    shadowColor: '#000',
    shadowOpacity: 0.05,
    shadowOffset: { width: 0, height: 1 },
  },
  postHeader: {
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  postAuthorImage: {
    width: 50,
    height: 50,
    borderRadius: 25,
    marginRight: 10,
  },
  postAuthorInfo: {
    justifyContent: 'center',
  },
  postAuthorName: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#333',
  },
  postAuthorRole: {
    fontSize: 14,
    color: '#666',
  },
  postContent: {
    fontSize: 16,
    color: '#333',
    marginBottom: 10,
    lineHeight: 22,
  },
  postImagesList: {
    marginBottom: 10,
  },
  postImage: {
    width: '100%',
    height: 200,
    borderRadius: 10,
    marginRight: 10,
    resizeMode: 'cover',
  },
  postActions: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    marginTop: 10,
    borderTopWidth: 1,
    borderTopColor: '#e0e0e0',
    paddingTop: 10,
  },
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionText: {
    marginLeft: 5,
    color: '#333',
    fontSize: 14,
  },
  likedText: {
    color: 'red',
    fontWeight: 'bold',
  },
  repliesContainer: {
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 20,
  },
  repliesTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    marginBottom: 10,
    color: '#007BFF',
  },
  repliesList: {
    paddingBottom: 10,
  },
  replyItem: {
    flexDirection: 'row',
    alignItems: 'flex-start',
    marginBottom: 15,
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 10,
    elevation: 1,
    shadowColor: '#000',
    shadowOpacity: 0.02,
    shadowOffset: { width: 0, height: 1 },
  },
  replyImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  replyContent: {
    flex: 1,
  },
  replyAuthor: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#333',
  },
  replyText: {
    fontSize: 16,
    color: '#666',
    marginTop: 5,
    lineHeight: 20,
  },
  noRepliesText: {
    fontSize: 18,
    color: '#666',
    textAlign: 'center',
    marginTop: 20,
  },
  addReplyContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    padding: 10,
    backgroundColor: '#ffffff',
    borderTopWidth: 1,
    borderTopColor: '#e0e0e0',
  },
  replyInput: {
    flex: 1,
    height: 60,
    borderColor: '#e0e0e0',
    borderWidth: 1,
    borderRadius: 10,
    padding: 10,
    marginRight: 10,
    backgroundColor: '#f9f9f9',
    fontSize: 16,
  },
  replyButton: {
    backgroundColor: '#007BFF',
    padding: 10,
    borderRadius: 20,
    justifyContent: 'center',
    alignItems: 'center',
  },
});

export default PostDetailScreen;
