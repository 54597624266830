// screens/HubsScreen.js
import React, { useEffect, useState } from 'react';
import { 
  View, 
  Text, 
  FlatList, 
  Image, 
  StyleSheet, 
  TouchableOpacity 
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation, useRoute } from '@react-navigation/native';
import { fetchHubs } from '../firebase_services/postService';
import ModelHub from '../models/ModelHub'; // Import ModelHub

const HubsScreen = () => {
  const navigation = useNavigation();
  const route = useRoute();
  const { onSelectHub } = route.params || {}; // Callback to pass selected hub back

  const [hubs, setHubs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const getHubs = async () => {
      try {
        const hubsList = await fetchHubs();
        // Transform raw hub data into ModelHub instances
        //const modelHubs = hubsList.map((hub) => console.log(hub)); //ModelHub.fromFirestore(hub));
        setHubs(hubsList);
      } catch (error) {
        console.error('Error fetching hubs:', error);
      } finally {
        setLoading(false);
      }
    };

    getHubs();
  }, []);

  const handleHubSelect = (hub) => {
    if (onSelectHub) {
      onSelectHub(hub);
    }
    navigation.goBack();
  };

  const renderItem = ({ item }) => (
    <TouchableOpacity style={styles.hubItem} onPress={() => handleHubSelect(item)}>
      <Image source={{ uri: item.hubImage }} style={styles.hubImage} />
      <View style={styles.hubTextContainer}>
        <Text style={styles.hubTitle}>{item.hubTitle}</Text>
        <Text style={styles.hubDescription}>{item.hubDescription}</Text>
      </View>
    </TouchableOpacity>
  );

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <Ionicons name="hourglass" size={50} color="#007BFF" />
        <Text style={styles.loadingText}>Loading Hubs...</Text>
      </View>
    );
  }

  return (
    <View style={styles.container}>
      {/* Header */}
      <View style={styles.header}>
        <TouchableOpacity onPress={() => navigation.goBack()} style={styles.backButton}>
          <Ionicons name="arrow-back" size={24} color="#007BFF" />
        </TouchableOpacity>
        <Image source={require('../assets/hamtiv-logo.png')} style={styles.logo} />
        <View style={{ width: 24 }} /> {/* Placeholder for alignment */}
      </View>

      {/* Hubs List */}
      <FlatList
        data={hubs}
        renderItem={renderItem}
        keyExtractor={(item) => item.hubId}
        contentContainerStyle={styles.hubsList}
        ItemSeparatorComponent={() => <View style={styles.separator} />}
        ListEmptyComponent={() => (
          <View style={styles.noHubsContainer}>
            <Text style={styles.noHubsText}>No Hubs Available.</Text>
          </View>
        )}
      />
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6',
  },
  header: {
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    paddingHorizontal: 15,
    backgroundColor: '#ffffff',
    elevation: 3,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    justifyContent: 'space-between',
  },
  backButton: {
    padding: 5,
  },
  logo: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
  hubsList: {
    padding: 10,
  },
  hubItem: {
    flexDirection: 'row',
    backgroundColor: '#ffffff',
    borderRadius: 10,
    padding: 10,
    alignItems: 'center',
    elevation: 2,
    shadowColor: '#000',
    shadowOpacity: 0.05,
    shadowOffset: { width: 0, height: 1 },
  },
  hubImage: {
    width: 60,
    height: 60,
    borderRadius: 10,
  },
  hubTextContainer: {
    marginLeft: 10,
    flex: 1,
  },
  hubTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    color: '#007BFF',
  },
  hubDescription: {
    color: '#666',
    marginTop: 5,
    fontSize: 14,
  },
  separator: {
    height: 10,
  },
  noHubsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },
  noHubsText: {
    fontSize: 18,
    color: '#666',
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  loadingText: {
    marginTop: 10,
    fontSize: 16,
    color: '#007BFF',
  },
});

export default HubsScreen;
