// models/ModelHub.js
class ModelHub {
    constructor(hubId, hubTitle, hubDescription, hubImage) {
      this.hubId = hubId;
      this.hubTitle = hubTitle;
      this.hubDescription = hubDescription;
      this.hubImage = hubImage;
    }
  
    /**
     * Creates an instance of ModelHub from a Firestore document.
     * @param {FirebaseFirestore.DocumentSnapshot} doc - Firestore document snapshot.
     * @returns {ModelHub} - An instance of ModelHub.
     */
    static fromFirestore(doc) {
      const data = doc.data();
      return new ModelHub(
        doc.id,
        data.hubTitle || 'No Title',
        data.hubDescription || 'No Description',
        data.hubImage || 'https://via.placeholder.com/150' // Placeholder image
      );
    }
  
    /**
     * Converts the ModelHub instance to a plain object for Firestore.
     * @returns {Object} - Plain object representation.
     */
    toFirestore() {
      return {
        hubTitle: this.hubTitle,
        hubDescription: this.hubDescription,
        hubImage: this.hubImage,
      };
    }
  }
  
  export default ModelHub;
  