// models/ModelUser.js
import { Timestamp } from 'firebase/firestore';

class ModelUser {
  constructor(
    userId,
    userName,
    userProfilePicture,
    userEmail,
    userPassword,
    userSummary,
    userSkills = [],
    userLinkedInLink,
    userGithubLink,
    userRole,
    userFollowers = [],
    userFollowing = [],
    userPosts = [],
    userExperiences = [],
    userEducation = [],
    userStream = '',           // Existing Field
    userDegree = '',           // Existing Field
    userDegreeBranch = '',     // Existing Field
    quizzesCompleted = {},    // New Field: { [quizId]: score }
    totalRewards = 0          // New Field: Total rewards in Rs
  ) {
    this.userId = userId;
    this.userName = userName;
    this.userProfilePicture = userProfilePicture;
    this.userEmail = userEmail;
    this.userPassword = userPassword;
    this.userSummary = userSummary;
    this.userSkills = userSkills;
    this.userLinkedInLink = userLinkedInLink;
    this.userGithubLink = userGithubLink;
    this.userRole = userRole;
    this.userFollowers = userFollowers;
    this.userFollowing = userFollowing;
    this.userPosts = userPosts;
    this.userExperiences = userExperiences;
    this.userEducation = userEducation;
    this.userStream = userStream;               
    this.userDegree = userDegree;               
    this.userDegreeBranch = userDegreeBranch;   
    this.quizzesCompleted = quizzesCompleted;  // New Field
    this.totalRewards = totalRewards;          // New Field
  }

  /**
   * Convert Firestore document to ModelUser instance
   * @param {DocumentSnapshot} doc
   * @returns {ModelUser}
   */
  static fromFirestore(doc) {
    const data = doc.data();
    return new ModelUser(
      doc.id,
      data.userName,
      data.userProfilePicture,
      data.userEmail,
      data.userPassword,
      data.userSummary,
      data.userSkills || [],
      data.userLinkedInLink,
      data.userGithubLink,
      data.userRole,
      data.userFollowers || [],
      data.userFollowing || [],
      data.userPosts || [],
      data.userExperiences || [],
      data.userEducation || [],
      data.userStream || '',          
      data.userDegree || '',          
      data.userDegreeBranch || '',     
      data.quizzesCompleted || {},    // New Field
      data.totalRewards || 0          // New Field
    );
  }

  /**
   * Convert ModelUser instance to Firestore document data
   * @returns {Object}
   */
  toFirestore() {
    return {
      userName: this.userName,
      userProfilePicture: this.userProfilePicture,
      userEmail: this.userEmail,
      userPassword: this.userPassword,
      userSummary: this.userSummary,
      userSkills: this.userSkills,
      userLinkedInLink: this.userLinkedInLink,
      userGithubLink: this.userGithubLink,
      userRole: this.userRole,
      userFollowers: this.userFollowers,
      userFollowing: this.userFollowing,
      userPosts: this.userPosts,
      userExperiences: this.userExperiences,
      userEducation: this.userEducation,
      userStream: this.userStream,             
      userDegree: this.userDegree,             
      userDegreeBranch: this.userDegreeBranch,  
      quizzesCompleted: this.quizzesCompleted, // New Field
      totalRewards: this.totalRewards          // New Field
    };
  }
}

export default ModelUser;
