// services/postService.js
import { 
  collection, 
  addDoc, 
  Timestamp, 
  query, 
  where, 
  orderBy, 
  limit, 
  onSnapshot, 
  doc, 
  getDoc, 
  getDocs,
  updateDoc, 
  arrayUnion, 
  arrayRemove 
} from 'firebase/firestore';
import { 
  getStorage, 
  ref, 
  uploadBytes, 
  getDownloadURL
} from 'firebase/storage';
import { db } from '../firebaseConfig';
import ModelPost from '../models/ModelPost';
import ModelHub from '../models/ModelHub';
// Reference to the 'posts' collection
const postsCollection = collection(db, 'posts');

// Reference to the 'hubs' collection
const hubsCollection = collection(db, 'hubs');

/**
 * Upload an image to Firebase Storage and return its download URL.
 * @param {string} uri - The local URI of the image to upload.
 * @param {string} userId - The UID of the user uploading the image.
 * @returns {Promise<string>} - The download URL of the uploaded image.
 */
export const uploadImageAsync = async (uri, userId) => {
  try {
    const storage = getStorage();
    const response = await fetch(uri);
    const blob = await response.blob();

    // Determine the file type from the URI
    const fileType = uri.substring(uri.lastIndexOf('.') + 1);
    const validFileTypes = ['jpg', 'jpeg', 'png', 'gif'];
    const extension = validFileTypes.includes(fileType.toLowerCase()) ? fileType.toLowerCase() : 'jpg';
    const mimeType = `image/${extension === 'jpg' ? 'jpeg' : extension}`;

    const imageRef = ref(storage, `postImages/${userId}/${Date.now()}.${extension}`);
    await uploadBytes(imageRef, blob, { contentType: mimeType });
    const downloadURL = await getDownloadURL(imageRef);
    return downloadURL;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

/**
 * Create a new post in Firestore.
 * @param {ModelPost} post - An instance of ModelPost with postAttachments already populated.
 * @returns {Promise<string>} - The ID of the created post.
 * @throws {Error} - If required fields are missing or adding the document fails.
 */
export const createPost = async (post) => {
  try {
    // Validate required fields
    if (!post.postContent || typeof post.postContent !== 'string') {
      throw new Error('Post content is required and must be a string.');
    }
    if (!post.postAuthorId || typeof post.postAuthorId !== 'string') {
      throw new Error('Post author ID is required and must be a string.');
    }
    if (!post.stream || typeof post.stream !== 'string') {
      throw new Error('Stream is required and must be a string.');
    }

    // Prepare the data with default values to avoid undefined fields
    const postToAdd = {
      postContent: post.postContent,
      postAttachments: post.postAttachments || [],
      postAuthorId: post.postAuthorId,
      postAuthorName: post.postAuthorName || 'Anonymous',
      postAuthorPicture: post.postAuthorPicture || 'https://example.com/default-user.png',
      postAuthorRole: post.postAuthorRole || 'User',
      postCreatedAt: Timestamp.fromDate(post.postCreatedAt || new Date()),
      postLikes: post.postLikes || [],
      postReports: post.postReports || [],
      postTags: post.postTags || [],
      postHub: post.postHub || null,
      parentPostId: post.parentPostId || null, // Assign parentPostId if exists
      stream: post.stream,
      degree: post.degree || '',
      branch: post.branch || '',
    };

    const docRef = await addDoc(postsCollection, postToAdd);
    return docRef.id;
  } catch (error) {
    console.error('Error adding post:', error);
    throw error;
  }
};

/**
 * Fetch all posts ordered by creation date descending
 * @returns {Promise<Array<ModelPost>>}
 */
export const fetchPosts = async () => {
  try {
    const q = query(postsCollection, orderBy('postCreatedAt', 'desc'));
    const querySnapshot = await getDocs(q); // Corrected from getDoc to getDocs
    const posts = querySnapshot.docs.map((doc) => ModelPost.fromFirestore(doc));
    return posts;
  } catch (error) {
    console.error('Error fetching posts: ', error);
    throw error;
  }
};

/**
 * Add a reply (subpost) to a post
 * @param {string} postId
 * @param {ModelPost} replyData
 * @returns {Promise<void>}
 */
export const addReply = async (postId, replyData) => {
  try {
    // Ensure the replyData has a parentPostId
    replyData.parentPostId = postId;
    const replyDataFirestore = replyData.toFirestore();
    await addDoc(postsCollection, replyDataFirestore);
  } catch (error) {
    console.error('Error adding reply: ', error);
    throw error;
  }
};

/**
 * Toggle like for a post
 * @param {string} postId
 * @param {string} userId
 * @returns {Promise<{liked: boolean, likesCount: number}>}
 */
export const toggleLike = async (postId, userId) => {
  try {
    const postDoc = doc(db, 'posts', postId);
    const postSnapshot = await getDoc(postDoc);

    if (postSnapshot.exists()) {
      const postData = postSnapshot.data();
      const isLiked = postData.postLikes && postData.postLikes.includes(userId);

      if (isLiked) {
        await updateDoc(postDoc, {
          postLikes: arrayRemove(userId),
        });
        return { liked: false, likesCount: postData.postLikes.length - 1 };
      } else {
        await updateDoc(postDoc, {
          postLikes: arrayUnion(userId),
        });
        return { liked: true, likesCount: postData.postLikes.length + 1 };
      }
    } else {
      throw new Error('Post does not exist.');
    }
  } catch (error) {
    console.error('Error toggling like: ', error);
    throw error;
  }
};

/**
 * Fetch hubs from Firestore.
 * @returns {Promise<Array<FirebaseFirestore.DocumentSnapshot>>} - Array of hub documents.
 * @throws {Error} - If fetching hubs fails.
 */
export const fetchHubs = async () => {
  try {
    const snapshot = await getDocs(hubsCollection);
    return snapshot.docs.map((doc) => ModelHub.fromFirestore(doc)); // Return DocumentSnapshots
  } catch (error) {
    console.error('Error fetching hubs:', error);
    throw error;
  }
};

/**
 * Subscribe to real-time updates of top-level posts (parentPostId is null)
 * @param {Function} callback - Function to call with updated posts.
 * @param {string|null} selectedHubId - Optional hub ID to filter posts.
 * @returns {Function} unsubscribe - Function to call to unsubscribe from updates.
 */
export const subscribeToTopLevelPosts = (callback, selectedHubId = null) => {
  let q;

  if (selectedHubId) {
    q = query(
      postsCollection, 
      where('postHub', '==', selectedHubId),
      where('parentPostId', '==', null), // Fetch only top-level posts
      orderBy('postCreatedAt', 'desc'), 
      limit(10)
    );
  } else {
    q = query(
      postsCollection, 
      where('parentPostId', '==', null), // Fetch only top-level posts
      orderBy('postCreatedAt', 'desc'), 
      limit(10)
    );
  }

  const unsubscribe = onSnapshot(
    q, 
    (snapshot) => {
      const posts = snapshot.docs.map((doc) => ModelPost.fromFirestore(doc));
      const lastDoc = snapshot.docs[snapshot.docs.length - 1];
      callback(posts, lastDoc);
    }, 
    (error) => {
      console.error('Error with real-time listener:', error);
    }
  );

  return unsubscribe;
};

/**
 * Subscribe to real-time updates of replies to a specific post
 * @param {string} parentPostId - The ID of the parent post
 * @param {Function} callback - Function to call with fetched replies
 * @returns {Function} unsubscribe - Function to call to unsubscribe from updates
 */
export const subscribeToReplies = (parentPostId, callback) => {
  const q = query(
    postsCollection,
    where('parentPostId', '==', parentPostId),
    orderBy('postCreatedAt', 'asc') // Or 'desc' based on preference
  );

  const unsubscribe = onSnapshot(
    q,
    (snapshot) => {
      const replies = snapshot.docs.map((doc) => ModelPost.fromFirestore(doc));
      callback(replies);
    },
    (error) => {
      console.error('Error subscribing to replies:', error);
    }
  );

  return unsubscribe;
};

// services/postService.js
// ... existing imports and code

/**
 * Count the number of replies to a specific post
 * @param {string} parentPostId - The ID of the parent post
 * @returns {Promise<number>}
 */
export const countReplies = async (parentPostId) => {
  try {
    const q = query(
      postsCollection,
      where('parentPostId', '==', parentPostId),
      limit(1000) // Firestore does not support count queries; use a high limit
    );
    const snapshot = await getDocs(q);
    return snapshot.size;
  } catch (error) {
    console.error('Error counting replies:', error);
    throw error;
  }
};

