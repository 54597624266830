// components/PostCardComponent.js
import React, { useState, useEffect, useContext } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  Dimensions,
  FlatList,
  Alert
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import { toggleLike, countReplies } from '../firebase_services/postService';
import ModelPost from '../models/ModelPost';
import { UserContext } from '../UserContext';
import { Dialog, Portal, Button as PaperButton, Paragraph } from 'react-native-paper'; // For dialogs

const windowWidth = Dimensions.get('window').width;
const isLargeDevice = windowWidth > 600; // Define breakpoint for large devices

const PostCardComponent = ({ post, onLike, onReply, onPostClick }) => {
  const navigation = useNavigation();
  const { user } = useContext(UserContext);
  const [likesCount, setLikesCount] = useState(Array.isArray(post.postLikes) ? post.postLikes.length : 0);
  const [isLiked, setIsLiked] = useState(false);
  const [replyCount, setReplyCount] = useState(0);

  // Dialog state variables
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogMessage, setDialogMessage] = useState('');

  console.log(Array.isArray(post.postLikes) ? post.postLikes.length : 0);

  // Handle Like Button Click
  const handleLike = async (postId) => {
    if (!user) {
      showDialog('Authentication Required', 'Please log in to like posts.');
      return;
    }
    try {
      console.log(postId);
      console.log(user.userId);
      const response = await toggleLike(postId, user.userId); // Assuming user.userId is already a string
      setLikesCount(response.likesCount);
    } catch (error) {
      console.error('Error liking post:', error);
      Alert.alert('Error', 'There was an error liking the post.');
    }
  };

  const handleShare = () => {
    showDialog('Feature Unavailability', 'This feature is not available right now.');
  }
  const handleBookmark = () => {
    showDialog('Feature Unavailability', 'This feature is not available right now.');
  }

  useEffect(() => {
    if (user && Array.isArray(post.postLikes)) {
      setIsLiked(post.postLikes.includes(user.userId));
    } else {
      setIsLiked(false);
    }

    // Fetch reply count
    const fetchReplyCount = async () => {
      try {
        const count = await countReplies(post.postId);
        setReplyCount(count);
      } catch (error) {
        console.error('Error fetching reply count:', error);
      }
    };

    fetchReplyCount();
  }, [post, user]);

  // Function to show dialog
  const showDialog = (title, message) => {
    setDialogTitle(title);
    setDialogMessage(message);
    setDialogVisible(true);
  };

  // Function to hide dialog
  const hideDialog = () => {
    setDialogVisible(false);
  };

  // const handleLike = () => {
  //   if (!user) {
  //     showDialog('Authentication Required', 'Please log in to like posts.');
  //     return;
  //   }
  //   onLike(); // Call the passed-in onLike function
  // };

  const handleReply = () => {
    if (!user) {
      showDialog('Authentication Required', 'Please log in to reply.');
      return;
    }
    onReply(); // Call the passed-in onReply function
  };

  const handlePostClick = () => {
    onPostClick(); // Call the passed-in onPostClick function
  };

  return (
    <View style={styles.card}>
      {/* User Info */}
      <TouchableOpacity style={styles.userInfo} onPress={handlePostClick}>
        {post.postAuthorPicture ? (
          <Image source={{ uri: post.postAuthorPicture }} style={styles.userImage} />
        ) : (
          <Ionicons name="person-circle-outline" size={40} color="#ccc" />
        )}
        <View>
          <Text style={styles.userName}>{post.postAuthorName}</Text>
          <Text style={styles.userRole}>{post.postAuthorRole}</Text>
        </View>
      </TouchableOpacity>

      {/* Post Content */}
      <TouchableOpacity onPress={handlePostClick}>
        <Text style={styles.postContent}>{post.postContent}</Text>
      </TouchableOpacity>

      {/* Post Attachments */}
      {Array.isArray(post.postAttachments) && post.postAttachments.length > 0 && (
        <FlatList
          data={post.postAttachments}
          horizontal
          keyExtractor={(item, index) => index.toString()}
          renderItem={({ item }) => (
            <Image source={{ uri: item }} style={styles.postImage} />
          )}
          showsHorizontalScrollIndicator={false}
        />
      )}

      {/* Post Actions */}
      <View style={styles.postActions}>

        {/* Like Button */}
        <TouchableOpacity onPress={() => handleLike(post.postId)} style={styles.actionButton}>
          <Ionicons name={isLiked ? 'heart' : 'heart-outline'} size={24} color={isLiked ? 'red' : '#333'} />
          <Text style={[styles.actionText, isLiked && styles.likedText]}>{likesCount}</Text>
        </TouchableOpacity>

        {/* View Replies Button */}
        <TouchableOpacity onPress={() => navigation.navigate('PostDetail', { post })} style={styles.actionButton}>
          <Ionicons name="chatbubble-outline" size={24} color="#333" />
          <Text style={styles.actionText}>{replyCount} Replies</Text>
        </TouchableOpacity>

        {/* Share Button */}
        <TouchableOpacity onPress={() => handleShare(post)} style={styles.actionButton}>
          <Ionicons name="share-social-outline" size={24} color="#333" />
          <Text style={styles.actionText}>Share</Text>
        </TouchableOpacity>

        {/* Bookmark Button */}
        <TouchableOpacity onPress={() => handleBookmark(post)} style={styles.actionButton}>
          <Ionicons name="bookmark-outline" size={24} color="#333" />
          <Text style={styles.actionText}>Bookmark</Text>
        </TouchableOpacity>
      </View>

      {/* Dialogs */}
      <Portal>
        <Dialog visible={dialogVisible} onDismiss={hideDialog}>
          <Dialog.Title>{dialogTitle}</Dialog.Title>
          <Dialog.Content>
            <Paragraph>{dialogMessage}</Paragraph>
          </Dialog.Content>
          <Dialog.Actions>
            <PaperButton onPress={hideDialog}>OK</PaperButton>
          </Dialog.Actions>
        </Dialog>
      </Portal>
    </View>
  );
};

const styles = StyleSheet.create({
  card: {
    width: isLargeDevice ? 800 : '100%', // 1000 width for large devices, full width for mobile
    maxWidth: 1000, // Prevent exceeding 1000px on very large screens
    backgroundColor: '#ffffff',
    borderRadius: 10,
    borderWidth: 1,
    borderColor: '#000', // Black border
    padding: 15,
    marginVertical: isLargeDevice ? 20 : 10, // Margin top and bottom
    alignSelf: 'center', // Center the card on large devices
    // Shadows for iOS
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 5,
    // Elevation for Android
    elevation: 3,
  },
  userInfo: {
    flexDirection: 'row',
    marginBottom: 10,
    alignItems: 'center',
    // cursor: 'pointer', // Not applicable in React Native
  },
  userImage: {
    width: 40,
    height: 40,
    borderRadius: 20,
    marginRight: 10,
  },
  userName: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  userRole: {
    fontSize: 12,
    color: '#666',
  },
  postContent: {
    fontSize: 16,
    color: '#333',
    marginBottom: 10,
  },
  postImage: {
    width: Dimensions.get('window').width - 10, // Adjusted width based on padding and margins
    height: (Dimensions.get('window').width - 90) * 0.50, // Maintain aspect ratio (e.g., 4:3)
    borderRadius: 10,
    marginRight: 10,
    resizeMode: 'cover',
    marginBottom: 10, // Space below the image
  },
  postActions: {
    flexDirection: 'row',
    marginTop: 10,
    justifyContent: 'space-around',
  },
  actionButton: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  actionText: {
    marginLeft: 5,
    fontSize: 14,
    color: '#333',
  },
  likedText: {
    color: 'red',
    fontWeight: 'bold',
  },
});

export default PostCardComponent;
