// screens/PostsScreen.js
import React, { useEffect, useState, useContext } from 'react';
import {
  View,
  Text,
  StyleSheet,
  Image,
  TouchableOpacity,
  FlatList,
  ActivityIndicator,
  Alert,
  RefreshControl,
  Dimensions,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import PostCardComponent from '../components/PostCardComponent';
import { subscribeToTopLevelPosts, toggleLike } from '../firebase_services/postService';
import { UserContext } from '../UserContext';
import { auth } from '../firebaseConfig';

const windowWidth = Dimensions.get('window').width;
const isLargeDevice = windowWidth > 600; // Define breakpoint for large devices

const PostsScreen = () => {
  const navigation = useNavigation();
  const { user } = useContext(UserContext); // Access user data from context
  const [posts, setPosts] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loading, setLoading] = useState(true);
  const [refreshing, setRefreshing] = useState(false);
  const [unsubscribePosts, setUnsubscribePosts] = useState(null); // State to hold unsubscribe function
  const [selectedHub, setSelectedHub] = useState(null); // State to hold selected hub

  useEffect(() => {
    // Unsubscribe from previous listener if any
    if (unsubscribePosts) {
      unsubscribePosts();
    }

    // Subscribe to top-level posts with optional hub filtering
    const unsubscribe = subscribeToTopLevelPosts((fetchedPosts, lastDoc) => {
      setPosts(fetchedPosts);
      setLastVisible(fetchedPosts.length === 10 ? lastDoc : null); // If less than limit, no more posts
      setLoading(false);
    }, selectedHub ? selectedHub.hubId : null); // Pass hubId if a hub is selected

    setUnsubscribePosts(() => unsubscribe); // Store unsubscribe function

    return () => {
      if (unsubscribePosts) {
        unsubscribePosts(); // Cleanup on unmount
      }
    };
  }, [selectedHub]); // Re-run effect when selectedHub changes

  // Handle Pull-to-Refresh
  const onRefresh = async () => {
    setRefreshing(true);
    setPosts([]);
    setLastVisible(null);
    if (unsubscribePosts) {
      unsubscribePosts(); // Unsubscribe from previous listener
    }
    try {
      const unsubscribe = subscribeToTopLevelPosts((fetchedPosts, lastDoc) => {
        setPosts(fetchedPosts);
        setLastVisible(fetchedPosts.length === 10 ? lastDoc : null);
        setLoading(false);
        setRefreshing(false);
      }, selectedHub ? selectedHub.hubId : null);
      setUnsubscribePosts(() => unsubscribe); // Update unsubscribe function
    } catch (error) {
      console.error('Error during refresh:', error);
      setRefreshing(false);
    }
  };

  // Handle Hubs Icon Click
  const handleHubsClick = () => {
    navigation.navigate('Hubs', {
      onSelectHub: (hub) => setSelectedHub(hub), // Callback to set selectedHub
    }); // Ensure 'Hubs' screen is defined in your navigator
  };

  // Handle Post Click to navigate to PostDetail
  const handlePostClick = (post) => {
    navigation.navigate('PostDetail', { post }); // Ensure 'PostDetail' is defined in your navigator
  };

  // Handle Reply Button Click
  const handleReply = (post) => {
    navigation.navigate('PostDetail', { post }); // Navigate to PostDetailScreen
  };

  // Render Individual Post
  const renderPost = ({ item }) => {
    return (
      <View style={styles.postItem}>
        <PostCardComponent
          post={item}
          onReply={() => handleReply(item)}
          onPostClick={() => handlePostClick(item)}
        />
      </View>
    );
  };

  // Render Footer Loader
  const renderFooter = () => {
    if (!lastVisible) return null;
    return (
      <View style={styles.loadingFooter}>
        <ActivityIndicator size="large" color="#007BFF" />
      </View>
    );
  };

  // Render Selected Hub Info with Image, Title, and Description
  const renderSelectedHub = () => {
    if (!selectedHub) return null;
    return (
      <View style={styles.selectedHubContainer}>
        {selectedHub.hubImage ? (
          <Image source={{ uri: selectedHub.hubImage }} style={styles.selectedHubImage} />
        ) : (
          <Ionicons name="image-outline" size={50} color="#ccc" style={styles.selectedHubImagePlaceholder} />
        )}
        <View style={styles.selectedHubTextContainer}>
          <Text style={styles.selectedHubTitle}>{selectedHub.hubTitle}</Text>
          <Text style={styles.selectedHubDescription}>{selectedHub.hubDescription}</Text>
        </View>
        <TouchableOpacity onPress={() => setSelectedHub(null)} style={styles.closeHubButton}>
          <Ionicons name="close-circle" size={24} color="#dc3545" />
        </TouchableOpacity>
      </View>
    );
  };

  if (loading) {
    return (
      <View style={styles.loadingContainer}>
        <ActivityIndicator size="large" color="#007BFF" />
      </View>
    );
  }

  return (
    <View style={styles.outerContainer}>
      {/* Header */}
      <View style={styles.header}>
        <Image source={require('../assets/hamtiv-logo.png')} style={styles.logo} />
        <Text style={styles.companyName}>Hamtiv</Text>
        <TouchableOpacity onPress={handleHubsClick}>
          <Ionicons name="ellipsis-horizontal" size={24} color="#333" />
        </TouchableOpacity>
      </View>

      {/* Display Selected Hub */}
      {renderSelectedHub()}

      {/* Posts List */}
      {posts.length > 0 ? (
        <FlatList
          data={posts}
          keyExtractor={(item) => item.postId}
          renderItem={renderPost}
          onEndReachedThreshold={0.5}
          ListFooterComponent={renderFooter}
          refreshControl={
            <RefreshControl refreshing={refreshing} onRefresh={onRefresh} />
          }
          contentContainerStyle={styles.contentContainer}
        />
      ) : (
        <View style={styles.noPostsContainer}>
          <Text style={styles.noPostsText}>No posts available to display.</Text>
        </View>
      )}

      {/* Floating Action Button */}
      {user && (
        <TouchableOpacity
          style={styles.fab}
          onPress={() => navigation.navigate('CreatePost')}
        >
          <Ionicons name="add" size={28} color="#fff" />
        </TouchableOpacity>
      )}
    </View>
  );
};

const styles = StyleSheet.create({
  outerContainer: {
    flex: 1,
    backgroundColor: '#F1EEE6', // Soothing light background
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    paddingHorizontal: 15,
    backgroundColor: '#ffffff',
    elevation: 3, // For Android shadow
    shadowColor: '#000', // For iOS shadow
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
  },
  logo: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
  },
  companyName: {
    fontSize: 20,
    fontWeight: 'bold',
    color: '#007BFF',
  },
  contentContainer: {
    paddingHorizontal: 15,
    paddingTop: 10,
    paddingBottom: 100, // To ensure FAB doesn't cover content
  },
  postItem: {
    marginBottom: 20, // Margin between posts
    // Width and maxWidth are handled inside PostCardComponent.js
  },
  loadingFooter: {
    paddingVertical: 20,
  },
  loadingContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noPostsContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
  },
  noPostsText: {
    fontSize: 18,
    color: '#666',
  },
  fab: {
    position: 'absolute',
    bottom: 30,
    right: 30,
    backgroundColor: '#007BFF',
    width: 60,
    height: 60,
    borderRadius: 30,
    justifyContent: 'center',
    alignItems: 'center',
    elevation: 5,
  },
  selectedHubContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#ffffff',
    padding: 15,
    marginHorizontal: 15,
    marginVertical: 15,
    borderRadius: 10,
    marginBottom: 10,
    shadowColor: '#000',
    shadowOpacity: 0.1,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 8,
    elevation: 3,
    justifyContent: 'space-between',
  },
  selectedHubImage: {
    width: 60,
    height: 60,
    borderRadius: 10,
    marginRight: 15,
  },
  selectedHubImagePlaceholder: {
    marginRight: 15,
  },
  selectedHubTextContainer: {
    flex: 1,
  },
  selectedHubTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#333333',
    marginBottom: 5,
  },
  selectedHubDescription: {
    fontSize: 14,
    color: '#666666',
  },
  closeHubButton: {
    padding: 5,
  },
});

export default PostsScreen;
