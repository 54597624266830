import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged, signInWithCredential, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { getStorage } from 'firebase/storage';
import { getFirestore } from 'firebase/firestore';
import { GoogleSignin } from '@react-native-google-signin/google-signin';
import { REACT_APP_FIREBASE_API_KEY, REACT_APP_FIREBASE_AUTH_DOMAIN, REACT_APP_FIREBASE_PROJECT_ID, REACT_APP_FIREBASE_STORAGE_BUCKET, REACT_APP_FIREBASE_MESSAGING_SENDER_ID, REACT_APP_FIREBASE_APP_ID, REACT_APP_FIREBASE_MEASUREMENT_ID, REACT_APP_FIREBASE_WEB_CLIENT_ID, REACT_APP_FIREBASE_ANDROID_CLIENT_ID } from '@env';


const firebaseConfig = {
  apiKey: REACT_APP_FIREBASE_API_KEY,
  authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: REACT_APP_FIREBASE_APP_ID,
  measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);
const storage = getStorage(app);

// // Enable offline persistence
// db.enablePersistence()
//   .catch((err) => {
//     if (err.code === 'failed-precondition') {
//       console.warn('Multiple tabs open, persistence can only be enabled in one tab at a a time.');
//     } else if (err.code === 'unimplemented') {
//       console.warn('The current browser does not support all of the features required to enable persistence');
//     }
//   });

GoogleSignin.configure({
  webClientId: REACT_APP_FIREBASE_WEB_CLIENT_ID,
  androidClientId: REACT_APP_FIREBASE_ANDROID_CLIENT_ID// From Firebase console
});

export { auth, db, GoogleAuthProvider, onAuthStateChanged, signInWithCredential, GoogleSignin , signInWithPopup, storage};