// screens/ExploreCareersScreen.js
import React, { useState, useEffect } from 'react';
import {
    View,
    Text,
    StyleSheet,
    Dimensions,
    TouchableOpacity,
    ScrollView,
    TextInput,
    Image,
    FlatList,
    Modal, // Import Modal for dialog
    Button, // Button for feedback submission
    Alert, // To show alert after feedback submission
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { useNavigation } from '@react-navigation/native';
import CategoryDropdown from '../components/CategoryDropdown';
import { careerData } from '../models/CareerPaths/careerPaths';
import { db } from '../firebaseConfig';
import { addDoc, collection } from 'firebase/firestore';

const windowWidth = Dimensions.get('window').width;
const isLargeDevice = windowWidth > 600; // Define breakpoint for large devices


const ExploreCareersScreen = () => {
    const navigation = useNavigation();
    const screenWidth = Dimensions.get('window').width;
    const [selectedStream, setSelectedStream] = useState('');
    const [selectedDegree, setSelectedDegree] = useState('');
    const [selectedBranch, setSelectedBranch] = useState('');
    const [filteredCareers, setFilteredCareers] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState(''); // New State for Search Keyword
    const [isModalVisible, setModalVisible] = useState(false); // Feedback modal state
    const [feedbackText, setFeedbackText] = useState(''); // Feedback input state

    // Filter careers based on selected stream, degree, branch, and search keyword
    useEffect(() => {
        let filterData = careerData;
        // Apply category filters

        if (selectedStream === '') {
            setSelectedDegree('')
            setSelectedBranch('')
        }
        if (selectedDegree === '') {
            setSelectedBranch('')
        }

        if (selectedStream) {
            filterData = filterData.filter(
                (career) => career.stream.toLowerCase() === selectedStream.toLowerCase()
            );
        }
        if (selectedDegree) {
            filterData = filterData.filter(
                (career) => career.degree.toLowerCase().includes(selectedDegree.toLowerCase())
            );
        }
        if (selectedBranch) {
            filterData = filterData.filter(
                (career) => career.branch.toLowerCase() === selectedBranch.toLowerCase()
            );
        }



        // Apply search keyword filter
        if (searchKeyword.trim() !== '') {
            const keyword = searchKeyword.toLowerCase();
            filterData = filterData.filter((career) => {
                // Check if the keyword is in the title or description
                const matchesTitleOrDescription = career.title.toLowerCase().includes(keyword) ||
                    career.description.toLowerCase().includes(keyword);

                // Check if the keyword is in the favouriteSubjects array
                const matchesFavouriteSubjects = career.favoriteSubjects.some(subject =>
                    subject.toLowerCase().includes(keyword)
                );

                const matchesFavouriteHobbies = career.favoriteHobbies.some(hobby =>
                    hobby.toLowerCase().includes(keyword)
                );

                // Return true if any of the conditions match
                return matchesTitleOrDescription || matchesFavouriteSubjects || matchesFavouriteHobbies;
            });
        }
        setFilteredCareers(filterData.sort((a, b) => a.title.localeCompare(b.title)));

        // Save search to Firebase if keyword is longer than 4 characters
        if (searchKeyword.trim().length > 4) {
            saveSearchToFirebase(searchKeyword);
        }


    }, [selectedStream, selectedDegree, selectedBranch, searchKeyword]);

    const saveSearchToFirebase = async (keyword) => {
        try {
            await addDoc(collection(db,'search'),{
                searchText: keyword,
                timestamp: new Date(),
            });
        } catch (error) {
            console.error('Error saving search:', error);
        }
    };

    const toggleModal = () => {
        setModalVisible(!isModalVisible);
    };

    const handleFeedbackSubmit = async () => {
        if (feedbackText.trim()) {
            try {
                await addDoc(collection(db, 'userFeedbacks'), {
                    feedbackText: feedbackText.trim(),
                    timestamp: new Date(),
                });
                Alert.alert('Thank you!', 'Your feedback has been submitted.');
                setFeedbackText(''); // Clear the input after submission
                toggleModal(); // Close the modal
            } catch (error) {
                console.error('Error submitting feedback:', error);
            }
        } else {
            Alert.alert('Please enter feedback.');
        }
    };

    const handleCareerClick = (career) => {
        navigation.navigate('CareerDetail', { career });
    };

    return (
        <ScrollView style={styles.container}>
            {/* Header */}
            <View style={styles.header}>
                <Image
                    source={require('../assets/hamtiv-logo.png')}
                    style={styles.logoHeader}
                />
                <Text style={styles.headerTitle}>Explore Career Paths</Text>
            </View>

            {/* Feedback Button */}
            <View style={styles.feedbackButtonContainer}>
                <Button title="Submit Feedback" onPress={toggleModal} />
            </View>

            {/* Feedback Modal */}
            <Modal
                animationType="slide"
                transparent={true}
                visible={isModalVisible}
                onRequestClose={toggleModal}
            >
                <View style={styles.modalView}>
                    <Text style={styles.modalText}>Tell us how we should improve your experience:</Text>
                    <TextInput
                        style={styles.feedbackInput}
                        placeholder="Enter your feedback"
                        value={feedbackText}
                        onChangeText={setFeedbackText}
                        multiline
                    />
                    <Button title="Submit Feedback" onPress={handleFeedbackSubmit} />
                    <TouchableOpacity onPress={toggleModal} style={styles.closeButton}>
                        <Text style={styles.closeButtonText}>Close</Text>
                    </TouchableOpacity>
                </View>
            </Modal>

            {/* Search Bar */}
            <View style={styles.searchContainer}>
                <Ionicons name="search" size={20} color="#666" style={styles.searchIcon} />
                <TextInput
                    style={styles.searchInput}
                    placeholder="Search careers based on interests..."
                    value={searchKeyword}
                    onChangeText={setSearchKeyword}
                    returnKeyType="search"
                />
                {searchKeyword !== '' && (
                    <TouchableOpacity onPress={() => setSearchKeyword('')} style={styles.clearIcon}>
                        <Ionicons name="close-circle" size={20} color="#666" />
                    </TouchableOpacity>
                )}
            </View>

            {/* Category Filters */}
            <CategoryDropdown
                selectedStream={selectedStream}
                onStreamChange={setSelectedStream}
                selectedDegree={selectedDegree}
                onDegreeChange={setSelectedDegree}
                selectedBranch={selectedBranch}
                onBranchChange={setSelectedBranch}
            />

            {/* Display message when no filters and no search keyword is selected */}
            {(!selectedStream && !selectedDegree && !selectedBranch && searchKeyword.trim() === '') && (
                <View style={styles.messageContainer}>
                    <Text style={styles.messageText}>Select Stream, Degree, Branch or enter a keyword to see career paths</Text>
                </View>
            )}

            {/* Display career cards if careers are available, otherwise display message */}
            {filteredCareers.length > 0 ? (
                <View style={screenWidth >= 768 ? styles.gridContainer : styles.verticalContainer}>
                    {filteredCareers.map((career, index) => (
                        <TouchableOpacity
                            key={career.title + index}
                            style={screenWidth >= 768 ? styles.cardLarge : styles.card}
                            onPress={() => handleCareerClick(career)}
                        >
                            <Text style={styles.careerTitle}>{career.title}</Text>
                            <Text style={styles.careerDescription}>
                                {career.description.length > 150
                                    ? `${career.description.substring(0, 150)}...`
                                    : career.description}
                            </Text>
                        </TouchableOpacity>
                    ))}
                </View>
            ) : (
                (selectedStream || selectedDegree || selectedBranch || searchKeyword.trim() !== '') && (
                    <Text style={styles.noResultsText}>No careers match your search criteria</Text>
                )
            )}
        </ScrollView>
    );
};

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: '#F1EEE6',
    },
    header: {
        backgroundColor: '#fff',
        paddingVertical: 10, // Reduced vertical padding
        paddingHorizontal: 20,
        flexDirection: 'column', // Stacks the title and subtitle vertically
        alignItems: 'center', // Centers content vertically
        justifyContent: 'center', // Ensures the content is centered
        borderBottomWidth: 1,
        borderBottomColor: '#ccc',
        position: 'relative', // For absolutely positioning the logo
        minHeight: 80, // Ensures the header has a minimum height for good spacing
    },
    logoHeader: {
        width: 40,
        height: 40,
        resizeMode: 'contain',
        position: 'absolute', // Places the logo in the top-left corner
        left: 20, // Keeps space from the left edge
        top: '50%', // Aligns vertically
        transform: [{ translateY: -20 }], // Centers logo vertically based on its height
    },
    headerTitle: {
        fontSize: 28,
        fontWeight: 'bold',
        color: '#007BFF',
        textAlign: 'center',
        marginRight: 5, // Adds a little space between the title and subtitle
    },
    feedbackButtonContainer: {
        padding: 10,
        alignItems: 'center',
    },
    modalView: {
        flex: 1,
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 20,
    },
    modalText: {
        fontSize: 18,
        fontWeight: 'bold',
        color: '#fff',
        marginBottom: 20,
    },
    feedbackInput: {
        backgroundColor: '#fff',
        width: '80%',
        padding: 10,
        borderRadius: 10,
        marginBottom: 20,
    },
    closeButton: {
        marginTop: 20,
    },
    closeButtonText: {
        color: '#fff',
        fontWeight: 'bold',
    },
    searchContainer: {
        flexDirection: 'row',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        borderRadius: 20,
        padding: 10,
        marginHorizontal: 15, // Centering the search bar with horizontal margins
        marginBottom: 15,
        marginTop: 15,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 8,
        elevation: 5,
        alignSelf: 'center', // Center the search bar horizontally
        width: isLargeDevice ? 500 : 350, // Fixed width
    },
    searchIcon: {
        marginRight: 10,
    },
    searchInput: {
        flex: 1,
        fontSize: 16,
        padding: 10,
        color: '#333333',
    },
    clearIcon: {
        marginLeft: 10,
    },
    verticalContainer: {
        padding: 20,
        alignItems: 'center',
    },
    gridContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
        paddingHorizontal: 20,
        paddingBottom: 20,
    },
    card: {
        backgroundColor: '#ffffff',
        borderRadius: 10,
        marginBottom: 20,
        padding: 20,
        width: '90%',
        maxWidth: 700,
        borderColor: '#007BFF', // Changed to primary color
        borderWidth: 2,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
    },
    cardLarge: {
        backgroundColor: '#ffffff',
        borderRadius: 10,
        padding: 20,
        marginBottom: 30,
        width: '48%',
        borderColor: '#007BFF', // Changed to primary color
        borderWidth: 2,
        shadowColor: '#000',
        shadowOpacity: 0.1,
        shadowOffset: { width: 0, height: 2 },
        shadowRadius: 10,
        elevation: 4,
    },
    careerTitle: {
        fontSize: 20,
        fontWeight: 'bold',
        color: '#333333',
        marginBottom: 10,
        textAlign: 'center',
    },
    careerDescription: {
        fontSize: 16,
        color: '#666666',
        textAlign: 'center',
        marginBottom: 20,
        lineHeight: 22,
    },
    noResultsText: {
        fontSize: 18,
        color: '#999999',
        textAlign: 'center',
        marginTop: 20,
    },
    messageContainer: {
        marginTop: 20,
        marginBottom: 10,
        alignItems: 'center',
    },
    messageText: {
        fontSize: 18,
        color: '#333333',
        fontWeight: 'bold',
        textAlign: 'center',
        paddingHorizontal: 20,
    },
});

export default ExploreCareersScreen;
