// contexts/UserContext.js
import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from './firebaseConfig';
import { onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import ModelUser from './models/ModelUser'; // Ensure the path is correct

// Create the context
export const UserContext = createContext(null);

// Create the provider component
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null); // Holds the ModelUser instance
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Subscribe to auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          // Fetch user document from Firestore
          const userDocRef = doc(db, 'users', currentUser.uid);
          const userDoc = await getDoc(userDocRef);

          if (userDoc.exists()) {
            // Convert Firestore data to ModelUser instance
            const fetchedUser = ModelUser.fromFirestore(userDoc);
            setUser(fetchedUser);
          } else {
            // Handle case where user document does not exist
            console.log('No such user document!');
            setUser(null);
          }
        } catch (error) {
          console.error('Error fetching user data:', error);
          setUser(null);
        }
      } else {
        // User is signed out
        setUser(null);
      }
      setLoading(false);
    });

    // Cleanup subscription on unmount
    return () => unsubscribe();
  }, []);

  return (
    <UserContext.Provider value={{ user, setUser, loading }}>
      {children}
    </UserContext.Provider>
  );
};
