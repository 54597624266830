// models/ModelPost.js
import { Timestamp } from 'firebase/firestore';

class ModelPost {
  constructor(
    postId,
    postContent,
    postAttachments = [],
    postAuthorId,
    postAuthorName,
    postAuthorPicture,
    postAuthorRole,
    postCreatedAt,
    postLikes = [],
    postReports = [],
    postTags = [],
    postHub = null,
    parentPostId = null, // New Field for Threaded Replies
    stream,     // Existing Field
    degree,     // Existing Field
    branch      // Existing Field
  ) {
    this.postId = postId;
    this.postContent = postContent;
    this.postAttachments = postAttachments;
    this.postAuthorId = postAuthorId;
    this.postAuthorName = postAuthorName;
    this.postAuthorPicture = postAuthorPicture;
    this.postAuthorRole = postAuthorRole;
    this.postCreatedAt = postCreatedAt;
    this.postLikes = postLikes;
    this.postReports = postReports;
    this.postTags = postTags;
    this.postHub = postHub;
    this.parentPostId = parentPostId; // Initialize the new field
    this.stream = stream;
    this.degree = degree;
    this.branch = branch;
  }

  /**
   * Convert Firestore document to ModelPost instance
   * @param {DocumentSnapshot} doc
   * @returns {ModelPost}
   */
  static fromFirestore(doc) {
    const data = doc.data();
    return new ModelPost(
      doc.id,
      data.postContent,
      data.postAttachments || [],
      data.postAuthorId,
      data.postAuthorName,
      data.postAuthorPicture,
      data.postAuthorRole,
      data.postCreatedAt instanceof Timestamp ? data.postCreatedAt.toDate() : data.postCreatedAt || new Date(),
      data.postLikes || [],
      data.postReports || [],
      data.postTags || [],
      data.postHub || null,
      data.parentPostId || null, // Assign parentPostId if exists
      data.stream || '',   // Existing Field
      data.degree || '',   // Existing Field
      data.branch || ''    // Existing Field
    );
  }

  /**
   * Convert ModelPost instance to Firestore document data
   * @returns {Object}
   */
  toFirestore() {
    return {
      postContent: this.postContent,
      postAttachments: this.postAttachments,
      postAuthorId: this.postAuthorId,
      postAuthorName: this.postAuthorName,
      postAuthorPicture: this.postAuthorPicture,
      postAuthorRole: this.postAuthorRole,
      postCreatedAt: Timestamp.fromDate(this.postCreatedAt),
      postLikes: this.postLikes,
      postReports: this.postReports,
      postTags: this.postTags,
      postHub: this.postHub,
      parentPostId: this.parentPostId, // Include parentPostId
      stream: this.stream,    // Existing Field
      degree: this.degree,    // Existing Field
      branch: this.branch     // Existing Field
    };
  }
}

export default ModelPost;
