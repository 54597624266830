// App.js
import { AppRegistry } from 'react-native';
import React from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { createBottomTabNavigator } from '@react-navigation/bottom-tabs';
import HomeScreen from './screens/HomeScreen';
import ProfileScreen from './screens/ProfileScreen';
import JobStack from './JobStack';
import ResourceStack from './ResourceStack';
import { Ionicons } from '@expo/vector-icons';
import { SafeAreaView, StyleSheet, View } from 'react-native';
import PostsStack from './PostsStack';
import ProfileStack from './ProfileStack';
import 'react-native-gesture-handler';
import 'react-native-reanimated';
import ExploreCareersScreen from './screens/ExploreCareersScreen';
import { UserProvider } from './UserContext'; // Import UserProvider
import CareerRoadmpaStack from './CareerRoadmapStack';
import { Provider } from 'react-native-paper';
import QuizStack from './QuizStack';

const Tab = createBottomTabNavigator();

export default function App() {
  return (
    <Provider>
      <UserProvider>
        <SafeAreaView style={styles.container}>
          <NavigationContainer>
            <View style={styles.innerContainer}>
              <Tab.Navigator
                initialRouteName="Explore Careers"
                screenOptions={({ route }) => ({
                  tabBarIcon: ({ color, size, focused }) => {
                    let iconName;
                    if (route.name === 'About') {
                      iconName = focused ? 'home' : 'home-outline';
                    } else if (route.name === 'Profile') {
                      iconName = focused ? 'person' : 'person-outline';
                    } else if (route.name === 'Explore Careers') {
                      iconName = focused ? 'bulb' : 'bulb-outline';
                    } else if (route.name === 'Learning') {
                      iconName = focused ? 'book' : 'book-outline';
                    } else if (route.name === 'Posts') {
                      iconName = focused ? 'chatbubbles' : 'chatbubbles-outline';
                    }
                    return <Ionicons name={iconName} size={size} color={color} />;
                  },
                  tabBarActiveTintColor: '#FFD700',
                  tabBarInactiveTintColor: '#808080',
                  tabBarStyle: styles.tabBar,
                  tabBarLabelStyle: styles.labelStyle,
                  headerShown: false,
                })}
              >
                <Tab.Screen name="Explore Careers" component={CareerRoadmpaStack} />
                <Tab.Screen name="Posts" component={PostsStack} />
                <Tab.Screen name="Profile" component={ProfileStack} />
                <Tab.Screen name="Learning" component={ResourceStack} />
                <Tab.Screen name="About" component={HomeScreen} />
              </Tab.Navigator>
            </View>
          </NavigationContainer>
        </SafeAreaView>
      </UserProvider>
    </Provider>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#1a1a1a',
  },
  innerContainer: {
    flex: 1,
    width: '100%',
  },
  tabBar: {
    backgroundColor: '#1a1a1a',
    borderTopWidth: 0,
    height: 60,
    paddingBottom: 10,
    paddingTop: 5,
  },
  labelStyle: {
    fontSize: 12,
    fontWeight: 'bold',
  },
});
