// ProfileScreen.js
import React, { useContext, useState, useEffect } from 'react';
import {
  View,
  Text,
  Image,
  StyleSheet,
  TouchableOpacity,
  ScrollView,
  ActivityIndicator,
  Linking,
  Modal,
  Alert,
  Dimensions,
} from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { auth, db, storage } from '../firebaseConfig';
import {
  GoogleAuthProvider,
  signOut,
  signInWithPopup,
} from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
} from 'firebase/firestore';
import EditProfileDialog from '../components/EditProfileDialog';
import { reportGardening } from '../components/reportJson';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; // Firebase Storage functions
import * as ImagePicker from 'expo-image-picker';
import CategoryDropdown from '../components/CategoryDropdown'; // Your existing CategoryDropdown component
import { UserContext } from '../UserContext'; // Import UserContext

const { width } = Dimensions.get('window'); // Get screen width to adjust layout
const isLargeScreen = width > 768; // Use large screen layout if width > 768

export default function ProfileScreen({ navigation }) {
  // Access user, setUser, and loading from UserContext
  const { user, setUser, loading } = useContext(UserContext);

  // Local state for report, editing, and dialogs
  const [report, setReport] = useState(null);
  const [editSection, setEditSection] = useState(null);
  const [editData, setEditData] = useState({});
  const [showReportMessage, setShowReportMessage] = useState(false);
  const [showReport, setShowReport] = useState(false); // Control report visibility
  const [showCategoryDialog, setShowCategoryDialog] = useState(false); // Category dialog visibility
  const [categoryData, setCategoryData] = useState({
    stream: '',
    degree: '',
    branch: '',
  }); // Selected category data
  const [uploading, setUploading] = useState(false); // Image upload state

  useEffect(() => {
    if (user) {
      fetchUserReport(user.userId);
      // Check if stream is set; if not, show the category dialog
      if (!user.userStream) {
        setShowCategoryDialog(true);
      }
    }
  }, [user]);

  // Fetch user report based on user ID
  const fetchUserReport = async (userId) => {
    try {
      const reportDoc = await getDoc(doc(db, 'reports', userId));
      if (reportDoc.exists()) {
        if (typeof reportDoc.data().report === 'string') {
          setReport(JSON.parse(reportDoc.data().report));
        } else {
          setReport(reportDoc.data().report);
        }
      } else {
        console.error('No report found for this user');
      }
    } catch (error) {
      console.error('Error fetching report:', error);
    }
  };

  // Handle Google Sign-In
  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      // The UserProvider will automatically handle setting the user
      // Additional logic can be added here if needed
    } catch (error) {
      console.error('Error during Google login:', error);
    }
  };

  // Handle Sign-Out
  const handleSignOut = async () => {
    try {
      await signOut(auth);
      // The UserProvider will automatically set user to null
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  // Handle editing different sections
  const handleEdit = (section, data) => {
    setEditSection(section);
    setEditData(data);
  };

  // Handle saving updated profile data
  const handleSave = async (updatedData) => {
    if (!user) return;
    const userId = user.id;
    try {
      if (
        updatedData.favoriteSubjects ||
        updatedData.favoriteActivities
      ) {
        const favoritesData = {
          userId,
          favoriteSubjects: updatedData.favoriteSubjects || [],
          favoriteActivities: updatedData.favoriteActivities || [],
        };
        await setDoc(doc(db, 'favourites', userId), favoritesData);
        setShowReportMessage(true);
      }

      await updateDoc(doc(db, 'users', userId), updatedData);
      // Update the user in context
      setUser({
        ...user,
        ...updatedData,
      });
    } catch (error) {
      console.error('Error updating profile:', error);
    } finally {
      setEditSection(null);
    }
  };

  // Toggle report visibility
  const toggleReportVisibility = () => {
    setShowReport(!showReport);
  };

  // Dismiss category dialog
  const handleDismissCategoryDialog = () => {
    setShowCategoryDialog(false);
  };

  // Save selected category data
  const handleCategorySave = async () => {
    const { stream, degree, branch } = categoryData;
    if (!stream) return; // Ensure stream is selected

    if (!user) return;
    const userId = user.id;

    try {
      const updatedProfile = {
        userStream: stream,
        userDegree: degree || '',
        userDegreeBranch: branch || '',
      };
      await updateDoc(doc(db, 'users', userId), updatedProfile);
      // Update the user in context
      setUser({
        ...user,
        ...updatedProfile,
      });
      setShowCategoryDialog(false);
    } catch (error) {
      console.error('Error updating category data:', error);
    }
  };

  // Pick and upload profile image
  const pickImage = async () => {
    // Ask for permission to access media library
    const { status } = await ImagePicker.requestMediaLibraryPermissionsAsync();
    if (status !== 'granted') {
      Alert.alert(
        'Permission Denied',
        'Permission to access media library is required to change the profile picture.'
      );
      return;
    }

    // Launch image picker
    let result = await ImagePicker.launchImageLibraryAsync({
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      aspect: [1, 1], // Square aspect ratio
      quality: 0.7,
    });

    if (!result.cancelled) {
      uploadImage(result.assets[0].uri);
    }
  };

  // Upload image to Firebase Storage
  const uploadImage = async (uri) => {
    setUploading(true);
    try {
      const response = await fetch(uri);
      const blob = await response.blob();

      const userId = user.id;
      const storageRef = ref(storage, `profilePictures/${userId}`);

      await uploadBytes(storageRef, blob);

      const downloadURL = await getDownloadURL(storageRef);

      // Update user profile with new image URL
      await updateDoc(doc(db, 'users', userId), {
        userProfilePicture: downloadURL,
      });

      // Update the user in context
      setUser({
        ...user,
        userProfilePicture: downloadURL,
      });

      Alert.alert('Success', 'Profile picture updated successfully.');
    } catch (error) {
      console.error('Error uploading image:', error);
      Alert.alert(
        'Upload Error',
        'An error occurred while uploading the image. Please try again.'
      );
    } finally {
      setUploading(false);
    }
  };

  // If the context is still loading, show a loading indicator
  if (loading) {
    return (
      <View style={styles.centered}>
        <ActivityIndicator size="large" color="#007bff" />
      </View>
    );
  }

  // If no user is logged in, show the login prompt
  if (!user) {
    return (
      <ScrollView
        style={styles.container}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {/* Header */}
        <View style={styles.header}>
          <Image
            source={require('../assets/hamtiv-logo.png')}
            style={styles.logoHeader}
          />
          <Text style={styles.headerTitle}>Profile</Text>
        </View>

        <View style={styles.centered}>
          {/* Google Login Button */}
          <TouchableOpacity
            onPress={handleGoogleLogin}
            style={styles.googleButton}
          >
            <Image
              style={styles.google_login}
              source={require('../assets/google-sign-in.png')}
            />
          </TouchableOpacity>

          {/* Login Action Text */}
          <Text style={styles.loginActionText}>
            Login and create your profile to generate a report for your career
            recommendations and roadmaps.
          </Text>

          {/* View/Hide Report Button */}
          <TouchableOpacity
            style={styles.toggleButton}
            onPress={toggleReportVisibility}
          >
            <Text style={styles.toggleButtonText}>
              {showReport ? 'Hide Report' : 'View Report'}
            </Text>
          </TouchableOpacity>

          {/* Descriptive Text When Not Logged In */}
          <Text style={styles.notLoggedInText}>
            Please log in to access your profile and personalized career
            assessment report.
          </Text>

          {/* Report Section */}
          {showReport && (
            <View style={styles.reportContainer}>
              <Text style={styles.reportTitle}>
                {reportGardening.name
                  ? `${reportGardening.name}'s Personalized Career Assessment Report`
                  : 'Personalized Career Assessment Report'}
              </Text>
              <Text style={styles.reportDate}>
                Date: {reportGardening.date || 'N/A'}
              </Text>
              {reportGardening.sections?.map((section, index) => (
                <View key={index} style={styles.sectionCard}>
                  <Text style={styles.sectionTitle}>{section.headline}</Text>
                  {section.content && (
                    <Text style={styles.sectionContent}>
                      {section.content}
                    </Text>
                  )}
                  {section.subsections?.map((sub, subIndex) => (
                    <View key={subIndex} style={styles.subsection}>
                      <Text style={styles.subsectionTitle}>
                        {sub.subheadline}
                      </Text>
                      {sub.points?.map((point, pointIndex) => (
                        <Text key={pointIndex} style={styles.point}>
                          {point}
                        </Text>
                      ))}
                    </View>
                  ))}
                </View>
              ))}
            </View>
          )}
        </View>
      </ScrollView>
    );
  }

  // Destructure user data from context
  const {
    userName,
    userProfilePicture,
    userEmail,
    userSummary,
    userSkills,
    userLinkedInLink,
    userGithubLink,
    userExperiences = [],
    userEducation = [],
    userProjects = [],
    favoriteSubjects = [],
    favoriteActivities = [],
    userStream,
    userDegree,
    userDegreeBranch,
  } = user;

  return (
    <ScrollView style={styles.container}>
      {/* Header */}
      <View style={styles.header}>
        <Image
          source={require('../assets/hamtiv-logo.png')}
          style={styles.logoHeader}
        />
        <Text style={styles.headerTitle}>Profile</Text>
      </View>

      {/* Buttons Layout */}
      <View
        style={
          isLargeScreen ? styles.buttonRowLarge : styles.buttonRowMobile
        }
      >
        <TouchableOpacity style={styles.logoutButton} onPress={handleSignOut}>
          <Text style={styles.logoutButtonText}>Logout</Text>
        </TouchableOpacity>
      </View>

      {/* Profile Picture */}
      <View style={styles.profilePictureContainer}>
        {userProfilePicture ? (
          <Image
            source={{ uri: userProfilePicture }}
            style={styles.profilePicture}
          />
        ) : (
          <Ionicons
            name="person-circle-outline"
            size={100}
            color="#ccc"
            style={styles.profilePicture}
          />
        )}
        <TouchableOpacity
          style={styles.editProfilePicture}
          onPress={pickImage}
        >
          {uploading ? (
            <ActivityIndicator size="small" color="#fff" />
          ) : (
            <Ionicons name="pencil" size={20} color="#fff" />
          )}
        </TouchableOpacity>
      </View>

      {/* Profile Cards */}
      <View
        style={
          isLargeScreen ? styles.gridContainer : styles.listContainer
        }
      >
        {/* Contact Details Card */}
        <View style={styles.card}>
          <TouchableOpacity
            onPress={() =>
              handleEdit('contactDetails', {
                userName,
                userEmail,
                userLinkedInLink,
                userGithubLink,
              })
            }
            style={styles.editIcon}
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
          <Text style={styles.cardTitle}>Contact Details</Text>
          <Text style={styles.description}>{userName}</Text>
          <Text style={styles.description}>{userEmail}</Text>
          {userLinkedInLink ? (
            <TouchableOpacity
              onPress={() => Linking.openURL(userLinkedInLink)}
            >
              <Text style={styles.linkText}>LinkedIn</Text>
            </TouchableOpacity>
          ) : null}
          {userGithubLink ? (
            <TouchableOpacity
              onPress={() => Linking.openURL(userGithubLink)}
            >
              <Text style={styles.linkText}>GitHub</Text>
            </TouchableOpacity>
          ) : null}
        </View>

        {/* Favorite Subjects & Activities */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>
            Favorite Subjects & Activities
          </Text>
          <View style={styles.skillsContainer}>
            {favoriteSubjects.map((subject, index) => (
              <Text key={index} style={styles.skillChip}>
                {subject}
              </Text>
            ))}
            {favoriteActivities.map((activity, index) => (
              <Text key={index} style={styles.skillChip}>
                {activity}
              </Text>
            ))}
          </View>
          <TouchableOpacity
            style={styles.editIcon}
            onPress={() =>
              handleEdit('favoriteSubjectsAndActivities', {
                favoriteSubjects,
                favoriteActivities,
              })
            }
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Summary */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Summary</Text>
          <Text style={styles.description}>{userSummary}</Text>
          <TouchableOpacity
            style={styles.editIcon}
            onPress={() =>
              handleEdit('summaryAndSkills', {
                userSummary,
                userSkills,
              })
            }
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Skills */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Skills</Text>
          <View style={styles.skillsContainer}>
            {userSkills.map((skill, index) => (
              <Text key={index} style={styles.skillChip}>
                {skill}
              </Text>
            ))}
          </View>
          <TouchableOpacity
            style={styles.editIcon}
            onPress={() =>
              handleEdit('summaryAndSkills', {
                userSummary,
                userSkills,
              })
            }
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Experience */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Experience</Text>
          {userExperiences.map((exp, index) => (
            <View key={index} style={styles.experienceItem}>
              <Text style={styles.experienceTitle}>{exp.title}</Text>
              <Text style={styles.description}>{exp.companyName}</Text>
              <Text style={styles.description}>
                {exp.startDate} - {exp.endDate}
              </Text>
              <Text style={styles.description}>{exp.location}</Text>
              <Text style={styles.description}>{exp.description}</Text>
            </View>
          ))}
          <TouchableOpacity
            style={styles.editIcon}
            onPress={() =>
              handleEdit('userExperiences', { userExperiences })
            }
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Education */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Education</Text>
          {userEducation.map((edu, index) => (
            <View key={index} style={styles.experienceItem}>
              <Text style={styles.experienceTitle}>{edu.degree}</Text>
              <Text style={styles.description}>{edu.institution}</Text>
              <Text style={styles.description}>
                {edu.startDate} - {edu.endDate}
              </Text>
            </View>
          ))}
          <TouchableOpacity
            style={styles.editIcon}
            onPress={() =>
              handleEdit('userEducation', { userEducation })
            }
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Projects */}
        <View style={styles.card}>
          <Text style={styles.cardTitle}>Projects</Text>
          {userProjects.map((proj, index) => (
            <View key={index} style={styles.experienceItem}>
              <Text
                style={styles.experienceTitle}
                onPress={() => Linking.openURL(proj.projectLink)}
              >
                {proj.title}
              </Text>
              <Text style={styles.description}>{proj.description}</Text>
              <Text style={styles.description}>
                {proj.startDate} - {proj.endDate}
              </Text>
              {proj.projectLink ? (
                <TouchableOpacity
                  onPress={() => Linking.openURL(proj.projectLink)}
                >
                  <Text style={styles.linkText}>Project Link</Text>
                </TouchableOpacity>
              ) : null}
            </View>
          ))}
          <TouchableOpacity
            style={styles.editIcon}
            onPress={() =>
              handleEdit('userProjects', { userProjects })
            }
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
        </View>

        {/* Academic Details Card */}
        <View style={styles.card}>
          <TouchableOpacity
            style={styles.editIcon}
            onPress={() => setShowCategoryDialog(true)}
          >
            <Ionicons name="pencil" size={24} color="#007BFF" />
          </TouchableOpacity>
          <Text style={styles.cardTitle}>Academic Details</Text>
          <Text style={styles.description}>
            <Text style={styles.boldText}>Stream:</Text> {userStream || 'N/A'}
          </Text>
          <Text style={styles.description}>
            <Text style={styles.boldText}>Degree:</Text> {userDegree || 'N/A'}
          </Text>
          <Text style={styles.description}>
            <Text style={styles.boldText}>Branch:</Text> {userDegreeBranch || 'N/A'}
          </Text>
        </View>
      </View>

      {/* View/Hide Report Button */}
      <TouchableOpacity
        style={styles.toggleButton}
        onPress={toggleReportVisibility}
      >
        <Text style={styles.toggleButtonText}>
          {showReport ? 'Hide Report' : 'View Report'}
        </Text>
      </TouchableOpacity>

      {/* Report Section in a Big Card */}
      {showReport && report && (
        <View style={styles.reportContainer}>
          {/* Report Title and Date */}
          <Text style={styles.reportTitle}>
            {report.name
              ? `${report.name}'s Personalized Career Assessment Report`
              : 'Personalized Career Assessment Report'}
          </Text>
          <Text style={styles.reportDate}>
            Date: {report.date || 'N/A'}
          </Text>

          {/* Iterate through sections */}
          {report.sections?.map((section, index) => (
            <View key={index} style={styles.sectionCard}>
              {/* Section Headline */}
              <Text style={styles.sectionTitle}>{section.headline}</Text>

              {/* Section Content */}
              {section.content ? (
                <Text style={styles.sectionContent}>
                  {section.content}
                </Text>
              ) : null}

              {/* Subsections */}
              {section.subsections?.map((sub, subIndex) => (
                <View key={subIndex} style={styles.subsection}>
                  <Text style={styles.subsectionTitle}>
                    {sub.subheadline}
                  </Text>
                  {sub.points?.map((point, pointIndex) => (
                    <Text key={pointIndex} style={styles.point}>
                      {point}
                    </Text>
                  ))}
                </View>
              ))}
            </View>
          ))}
        </View>
      )}

      {/* Edit Profile Dialog */}
      {editSection && (
        <EditProfileDialog
          visible={!!editSection}
          section={editSection}
          data={editData}
          onClose={() => setEditSection(null)}
          onSave={handleSave}
        />
      )}

      {/* Category Selection Dialog */}
      <Modal
        visible={showCategoryDialog}
        transparent={true}
        animationType="slide"
        onRequestClose={() => setShowCategoryDialog(false)}
      >
        <View style={styles.modalContainer}>
          <View style={styles.modalContent}>
            <Text style={styles.modalTitle}>Select Academic Details</Text>

            {/* Integrate CategoryDropdown */}
            <CategoryDropdown
              selectedStream={categoryData.stream}
              onStreamChange={(value) => {
                setCategoryData({
                  ...categoryData,
                  stream: value,
                  degree: '',
                  branch: '',
                });
              }}
              selectedDegree={categoryData.degree}
              onDegreeChange={(value) => {
                setCategoryData({
                  ...categoryData,
                  degree: value,
                  branch: '',
                });
              }}
              selectedBranch={categoryData.branch}
              onBranchChange={(value) => {
                setCategoryData({
                  ...categoryData,
                  branch: value,
                });
              }}
              disabled={false}
            />

            {/* Buttons */}
            <View style={styles.modalButtons}>
              <TouchableOpacity
                style={[
                  styles.modalButton,
                  { backgroundColor: categoryData.stream ? '#28a745' : '#ccc' },
                ]}
                onPress={handleCategorySave}
                disabled={!categoryData.stream}
              >
                <Text style={styles.modalButtonText}>Save</Text>
              </TouchableOpacity>
              <TouchableOpacity
                style={[styles.modalButton, { backgroundColor: '#dc3545' }]}
                onPress={handleDismissCategoryDialog}
              >
                <Text style={styles.modalButtonText}>Cancel</Text>
              </TouchableOpacity>
            </View>
          </View>
        </View>
      </Modal>
    </ScrollView>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#F1EEE6',
  },
  modalContainer: {
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 22,
    backgroundColor: 'rgba(0,0,0,0.5)',
  },
  modalContent: {
    width: '85%',
    backgroundColor: 'white',
    borderRadius: 20,
    padding: 35,
    alignItems: 'stretch',
    shadowColor: '#000',
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 4,
    elevation: 5,
  },
  modalTitle: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 15,
    textAlign: 'center',
    color: '#007BFF',
  },
  modalButtons: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginTop: 10,
  },
  modalButton: {
    flex: 1,
    padding: 10,
    borderRadius: 5,
    marginHorizontal: 5,
    alignItems: 'center',
  },
  modalButtonText: {
    color: '#fff',
    fontWeight: 'bold',
  },
  header: {
    backgroundColor: '#fff',
    paddingVertical: 10, // Reduced vertical padding
    paddingHorizontal: 20,
    flexDirection: 'column', // Stacks the title and subtitle vertically
    alignItems: 'center', // Centers content vertically
    justifyContent: 'center', // Ensures the content is centered
    borderBottomWidth: 1,
    borderBottomColor: '#ccc',
    position: 'relative', // For absolutely positioning the logo
    minHeight: 80, // Ensures the header has a minimum height for good spacing
  },
  logoHeader: {
    width: 40,
    height: 40,
    resizeMode: 'contain',
    position: 'absolute', // Places the logo in the top-left corner
    left: 20, // Keeps space from the left edge
    top: '50%', // Aligns vertically
    transform: [{ translateY: -20 }], // Centers logo vertically based on its height
  },
  headerTitle: {
    fontSize: 28,
    fontWeight: 'bold',
    color: '#007BFF',
    textAlign: 'center',
    marginRight: 5, // Adds a little space between the title and subtitle
  },
  google_login: {
    width: 300,
    height: 200,
    resizeMode: 'contain',
    marginBottom: 15,
  },
  centered: {
    flex: 1,
    alignItems: 'center',
    paddingVertical: 20,
  },
  notLoggedInText: {
    color: '#1e1e1e',
    fontWeight: 'bold',
    fontSize: 18,
    margin: 15,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
  buttonRowMobile: {
    justifyContent: 'center',
    alignItems: 'center',
    marginVertical: 20,
    marginHorizontal: 20,
    paddingHorizontal: 20,
  },
  buttonRowLarge: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginVertical: 20,
    marginHorizontal: 20,
    paddingHorizontal: 20,
  },
  logoutButton: {
    position: 'absolute',
    left: 20,
    flexDirection: 'row',
    alignItems: 'center',
    backgroundColor: '#dc3545', // Bootstrap danger color
    paddingVertical: 8,
    paddingHorizontal: 12,
    borderRadius: 5,
  },
  logoutButtonText: {
    color: '#fff',
    marginLeft: 5,
    fontSize: 16,
    fontWeight: 'bold',
  },
  profilePictureContainer: {
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center', // Centers the profile picture horizontally
    marginTop: 20, // Add space from the top if needed
  },
  profilePicture: {
    width: 100,
    height: 100,
    borderRadius: 50, // Makes the image a circle
    borderWidth: 2,
    borderColor: '#007BFF',
  },
  editProfilePicture: {
    top: -110, // Adjust this value to move the edit icon vertically
    right: -30, // Adjust this value to move the edit icon horizontally
    backgroundColor: '#007BFF',
    borderRadius: 15,
    padding: 5,
    borderWidth: 2,
    borderColor: '#fff',
  },
  exploreButton: {
    backgroundColor: '#FFD700',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginTop: 20,
  },
  exploreButtonText: {
    color: '#1e1e1e',
    fontWeight: 'bold',
    fontSize: 20,
  },
  loginActionText: {
    color: '#1e1e1e',
    fontWeight: 'bold',
    fontSize: 20,
    margin: 15,
    paddingVertical: 15,
    paddingHorizontal: 10,
    textAlign: 'center',
  },
  gridContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: 10,
  },
  listContainer: {
    flexDirection: 'column',
    padding: 10,
  },
  editIcon: {
    position: 'absolute', // Allows positioning in relation to the card
    top: 10, // Adjust this value to move it vertically
    right: 10, // Adjust this value to move it horizontally
    zIndex: 1, // Ensures the icon is above other card content
  },
  card: {
    width: isLargeScreen ? '45%' : '90%',
    backgroundColor: '#fff',
    padding: 20,
    marginVertical: 10,
    marginHorizontal: 10,
    borderRadius: 10,
    elevation: 5,
    shadowColor: '#000',
    shadowOffset: { width: 0, height: 2 },
    shadowOpacity: 0.2,
    shadowRadius: 4,
    position: 'relative',
  },
  cardTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    marginBottom: 15,
    color: '#007BFF',
  },
  description: {
    color: '#333',
    fontSize: 18,
    marginBottom: 5,
  },
  boldText: {
    fontWeight: 'bold',
    color: '#333',
  },
  skillsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
  },
  skillChip: {
    backgroundColor: '#007BFF',
    color: '#fff',
    paddingVertical: 5,
    paddingHorizontal: 10,
    marginVertical: 5,
    marginHorizontal: 5,
    borderRadius: 15,
    fontSize: 14,
  },
  experienceItem: {
    marginBottom: 15,
  },
  experienceTitle: {
    fontWeight: 'bold',
    fontSize: 16,
    marginBottom: 5,
    color: '#007BFF',
  },
  linkText: {
    color: '#FF6F61',
    textDecorationLine: 'underline',
    marginTop: 5,
  },
  reportContainer: {
    padding: 20,
    backgroundColor: '#fff',
    borderRadius: 10,
    margin: 20,
    elevation: 5,
  },
  reportTitle: {
    fontSize: 22,
    fontWeight: 'bold',
    color: '#007BFF',
    textAlign: 'center',
    marginBottom: 10,
  },
  reportDate: {
    fontSize: 16,
    color: '#6c757d',
    textAlign: 'center',
    marginBottom: 20,
  },
  sectionCard: {
    backgroundColor: '#f9f9f9',
    padding: 15,
    borderRadius: 10,
    marginVertical: 10,
    elevation: 3,
  },
  sectionTitle: {
    fontSize: 18,
    fontWeight: 'bold',
    color: '#007BFF',
    marginBottom: 10,
  },
  sectionContent: {
    fontSize: 16,
    color: '#333',
    marginBottom: 10,
  },
  subsection: {
    marginBottom: 20,
  },
  subsectionTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#28a745',
    marginBottom: 5,
  },
  point: {
    fontSize: 16,
    color: '#333',
    marginBottom: 5,
  },
  reportMessage: {
    backgroundColor: '#FF5722',
    color: '#ffffff',
    fontSize: 16,
    padding: 10,
    borderRadius: 5,
    textAlign: 'center',
    marginVertical: 10,
  },
  toggleButton: {
    backgroundColor: '#007BFF',
    paddingVertical: 15,
    paddingHorizontal: 30,
    borderRadius: 8,
    marginVertical: 10,
    alignItems: 'center',
    alignSelf: 'center',
  },
  toggleButtonText: {
    color: '#fff',
    fontSize: 16,
    fontWeight: 'bold',
  },
  highlightedExploreButton: {
    backgroundColor: '#FFD700',
    paddingVertical: 20, // Increased padding
    paddingHorizontal: 40,
    borderRadius: 12,
    marginTop: 30, // Additional margin for spacing
    elevation: 8, // Shadow effect
    shadowColor: '#000',
    shadowOpacity: 0.25,
    shadowOffset: { width: 0, height: 2 },
    shadowRadius: 10,
    alignItems: 'center',
    width: '80%', // Make button wider
  },
  highlightedExploreButtonText: {
    color: '#1e1e1e',
    fontWeight: 'bold',
    fontSize: 26, // Larger font size for prominence
    textAlign: 'center',
  },
  googleButton: {
    marginTop: 20,
  },
});
